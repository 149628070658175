import React from 'react'

import { BrowserRouter, Route } from 'react-router-dom'

import Loadable from 'react-loadable'

import 'antd/dist/antd.css'
import './styles/App.scss'

const Home = Loadable({
	loader: () => import('./components/Home'),
	loading(){
        return "Loading"
    },
})

class App extends React.PureComponent {
	render() {
		return (
			<BrowserRouter hashType="noslash">
				<div>
					<Route exact path="/" component={Home} />
				</div>
			</BrowserRouter>
		)
	}
}

export default App
